
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2  } from '../../../../Constant/Constant';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';


const FooterContent = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        SignupText: '',
        LoginText: '',
        PrivacyPolicyText: '',
        RefundText: '',
        LicensesText: '',
        TermsConditionsText: '',
        FaqText: '',
        BusinessText: '',
        ContactusText: '',
        AboutusText: '',
        AccountText: '',
        LegalText: '',
        CompanyText: '',
        RegisterText: '',
        ServiceText: '',
        OurFleetText: '',
        HomeText: '',
        PhoneNumber: '',
        // CustomerGuidelines:''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const [id, setId] = useState(null); // Initialize with null
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);
  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);

        const fetchData = async () => {
            try {
                const url = `${BASE_URL}staticcontent/get?language_id=${languageId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setId(response.data.id);
                if (response.data && response.data.footercontent) {
                    const parsedData = JSON.parse(response.data.footercontent);
                    setFormValues(parsedData);
                    setIsDataExists(true);
                    setIsEditMode(false); // Data exists, so not in edit mode


                } else {

                    setFormValues({
                        SignupText: '',
                        LoginText: '',
                        PrivacyPolicyText: '',
                        RefundText: '',
                        LicensesText: '',
                        TermsConditionsText: '',
                        FaqText: '',
                        BusinessText: '',
                        ContactusText: '',
                        AboutusText: '',
                        AccountText: '',
                        LegalText: '',
                        CompanyText: '',
                        RegisterText: '',
                        ServiceText: '',
                        OurFleetText: '',
                        HomeText: '',
                        PhoneNumber: '',
                        // CustomerGuidelines:""
                    });
                    setIsDataExists(false);
                    setIsEditMode(true); // No data, so allow adding
                }
            } catch (error) {
                setIsEditMode(true); // Error fetching data, possibly allow editing
            }
        };

      

    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                footercontent: formValues,
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/footercontent`;

            const response = await axios.post(url, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Check if response data is in the expected format
            if (response.data && response.data.message === 'footercontent Process successfully!') {
                toast.dismiss()
                toast.success('Data processed successfully!', {
                    autoClose: 1500,
                });
                setIsDataExists(true);

            } else {
                toast.dismiss()
                toast.warn('Unexpected response from the server.', {
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <form className="row g-3" onSubmit={handleSubmit}>
                {/* <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Company Text</label>
                        <input
                            name="CompanyText"
                            type="text"
                            className="form-control"
                            value={formValues.CompanyText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Customer Gudeline Title</label>
                        <input
                            name="CustomerGuidelines"
                            type="text"
                            className="form-control"
                            value={formValues.CustomerGuidelines}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div> */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Account Text</label>
                        <input
                            name="AccountText"
                            type="text"
                            className="form-control"
                            value={formValues.AccountText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>


                {/* Second Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Aboutus Text</label>
                        <input
                            name="AboutusText"
                            type="text"
                            className="form-control"
                            value={formValues.AboutusText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Contactus Text</label>
                        <input
                            name="ContactusText"
                            type="text"
                            className="form-control"
                            value={formValues.ContactusText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Business Text</label>
                        <input
                            name="BusinessText"
                            type="text"
                            className="form-control"
                            value={formValues.BusinessText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>

                {/* three Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Faq Text</label>
                        <input
                            name="FaqText"
                            type="text"
                            className="form-control"
                            value={formValues.FaqText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Terms&Conditions Text</label>
                        <input
                            name="TermsConditionsText"
                            type="text"
                            className="form-control"
                            value={formValues.TermsConditionsText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">LicensesText</label>
                        <input
                            name="LicensesText"
                            type="text"
                            className="form-control"
                            value={formValues.LicensesText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>

                {/* fouth Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Refund Text</label>
                        <input
                            name="RefundText"
                            type="text"
                            className="form-control"
                            value={formValues.RefundText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">PrivacyPolicy Text</label>
                        <input
                            name="PrivacyPolicyText"
                            type="text"
                            className="form-control"
                            value={formValues.PrivacyPolicyText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">LoginText</label>
                        <input
                            name="LoginText"
                            type="text"
                            className="form-control"
                            value={formValues.LoginText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>

                {/* five Row */}
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">SignupText</label>
                        <input
                            name="SignupText"
                            type="text"
                            className="form-control"
                            value={formValues.SignupText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Phone Number</label>
                        <input
                            name="PhoneNumber"
                            type="text"
                            className="form-control"
                            value={formValues.PhoneNumber}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Home Text</label>
                        <input
                            name="HomeText"
                            type="text"
                            className="form-control"
                            value={formValues.HomeText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">OurFleet Text</label>
                        <input
                            name="OurFleetText"
                            type="text"
                            className="form-control"
                            value={formValues.OurFleetText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Service Text</label>
                        <input
                            name="ServiceText"
                            type="text"
                            className="form-control"
                            value={formValues.ServiceText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Register Text</label>
                        <input
                            name="RegisterText"
                            type="text"
                            className="form-control"
                            value={formValues.RegisterText}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div>
                {/* <div className="col-md-4">
                    <div className="mb-3">
                        <label className="form-label">Customer Guideline Title</label>
                        <input
                            name="CustomerGuidelines"
                            type="text"
                            className="form-control"
                            value={formValues.CustomerGuidelines}
                            onChange={handleChange}
                          disabled={loading ||isBookingBoxData}
                        />
                    </div>
                </div> */}

                <div className="card-footer text-end">
                {rolePermissions.write === 'yes' && 
                    <button
                        type="submit"
                        className="btn btn-primary"
                      disabled={loading ||isBookingBoxData}
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
}
                </div>

            </form>
        </>
    );
};

export default FooterContent;
