import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

const AsideMenu = () => {
    const [activeDropdown, setActiveDropdown] = useState(null);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({});
    const location = useLocation();

    useEffect(() => {
        if (permissions) {
            try {
                const modules = JSON.parse(permissions);
                const permissionsMap = {};
                modules.forEach(module => {
                    permissionsMap[module.rolename] = module.permissions;
                });
                setRolePermissions(permissionsMap);
            } catch (error) {
                console.error('Failed to parse permissions:', error);
            }
        }
    }, [permissions]);

    const handleMouseEnter = (dropdownName) => {
        setActiveDropdown(dropdownName);
    };

    const handleMouseLeave = () => {
        setActiveDropdown(null);
    };

    const isActiveMenu = (mainPath, subPaths = []) => {
        return (
            location.pathname === mainPath ||
            subPaths.some(subPath => location.pathname === subPath)
        );
    };


    return (
        <aside id="layout-menu" className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0">
            <div className=" d-flex h-100" style={{ marginLeft: '40px' }}>
                <ul className="menu-inner pb-2 pb-xl-0">
                    <li className={`menu-item ${isActiveMenu('/dashboard') ? 'active' : ''}`}>
                        <Link to="./dashboard" className="menu-link">
                            <i className="menu-icon tf-icons ti ti-smart-home"></i>
                            <div data-i18n="Dashboards">Dashboards</div>
                        </Link>
                    </li>
                    {/* Page Settings */}
                    {(rolePermissions['aboutus'] && (rolePermissions['aboutus'].read === 'yes' || rolePermissions['aboutus'].write === 'yes')) ||
                        (rolePermissions['legalcontent'] && (rolePermissions['legalcontent'].read === 'yes' || rolePermissions['legalcontent'].write === 'yes')) ||
                        (rolePermissions['faq'] && (rolePermissions['faq'].read === 'yes' || rolePermissions['faq'].write === 'yes')) ||
                        (rolePermissions['contactus'] && (rolePermissions['contactus'].read === 'yes' || rolePermissions['contactus'].write === 'yes')) ||
                        (rolePermissions['staticcontent'] && (rolePermissions['staticcontent'].read === 'yes' || rolePermissions['staticcontent'].write === 'yes')) ? (
                        <li className={`menu-item ${isActiveMenu('#', ['/aboutus', '/add-user', '/legalcontents', '/addlegalcontents', '/faq', '/contactus', '/addcontactus', '/staticcontent']) || window.location.pathname.includes('/actionabout') || window.location.pathname.includes('/actionlegal') || window.location.pathname.includes('/faqaction') || window.location.pathname.includes('/actioncontact') ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter('pagesDropdown')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="#" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ti ti-file"></i>
                                <div data-i18n="Layouts">Page Settings</div>
                            </Link>
                            <ul className="menu-sub" style={{ display: activeDropdown === 'pagesDropdown' ? 'block' : 'none' }}>

                                {rolePermissions['aboutus'] && (rolePermissions['aboutus'].read === 'yes' || rolePermissions['aboutus'].write === 'yes') && (
                                    <li className={`menu-item ${isActiveMenu('#', ['/aboutus', '/add-user', '/actionabout']) || window.location.pathname.includes('/actionabout') ? 'active' : ''}`}>
                                        <Link to="/aboutus" className="menu-link">
                                            <i className="menu-icon tf-icons ti ti-info-circle"></i>
                                            <div data-i18n="Without menu">About Page</div>
                                        </Link>
                                    </li>
                                )}

                                {rolePermissions['legalcontent'] && (rolePermissions['legalcontent'].read === 'yes' || rolePermissions['legalcontent'].write === 'yes') && (
                                    <li className={`menu-item ${isActiveMenu('#', ['/legalcontents', '/addlegalcontents']) || window.location.pathname.includes('/actionlegal') ? 'active' : ''}`}>
                                        <Link to="/legalcontents" className="menu-link">
                                            <i className="menu-icon tf-icons ti ti-layout-distribute-vertical"></i>
                                            <div data-i18n="Vertical">Legal Contents</div>
                                        </Link>
                                    </li>
                                )}

                                {rolePermissions['faq'] && (rolePermissions['faq'].read === 'yes' || rolePermissions['faq'].write === 'yes') && (
                                    <li className={`menu-item ${location.pathname === '/faq' || window.location.pathname.includes('/faqaction') ? 'active' : ''}`}>
                                        <Link to="/faq" className="menu-link">
                                            <i className="menu-icon tf-icons ti ti-square"></i>
                                            <div data-i18n="Blank">FAQ</div>
                                        </Link>
                                    </li>
                                )}

                                {rolePermissions['contactus'] && (rolePermissions['contactus'].read === 'yes' || rolePermissions['contactus'].write === 'yes') && (
                                    <li className={`menu-item ${isActiveMenu('#', ['/contactus', '/addcontactus']) || window.location.pathname.includes('/actioncontact') ? 'active' : ''}`}>
                                        <Link to="/contactus" className="menu-link">
                                            <i className="menu-icon tf-icons ti ti-square"></i>
                                            <div data-i18n="Blank">Contact Us</div>
                                        </Link>
                                    </li>
                                )}

                                {rolePermissions['staticcontent'] && (rolePermissions['staticcontent'].read === 'yes' || rolePermissions['staticcontent'].write === 'yes') && (
                                    <li className={`menu-item ${location.pathname === '/staticcontent' ? 'active' : ''}`}>
                                        <Link to="/staticcontent" className="menu-link">
                                            <i className="menu-icon tf-icons ti ti-square"></i>
                                            <div data-i18n="Blank">Static Content</div>
                                        </Link>
                                    </li>
                                )}

                            </ul>
                        </li>
                    ) : null}
                    {/* Services */}
                    {rolePermissions['services'] && (rolePermissions['services'].read === 'yes' || rolePermissions['services'].write === 'yes') && (
                        <li
                            className={`menu-item ${isActiveMenu('#', ['/addservices', '/serviceslist']) || window.location.pathname.includes('/actionservice') ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter('servicesDropdown')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="#" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ti ti-settings"></i>
                                <div data-i18n="Layouts">Services</div>
                            </Link>
                            <ul className="menu-sub"
                                style={{ display: activeDropdown === 'servicesDropdown' ? 'block' : 'none' }}
                            >
                                {rolePermissions['services'] && rolePermissions['services'].write === 'yes' && (
                                    <li className={`menu-item ${location.pathname === '/addservices' ? 'active' : ''}`}>
                                        <Link to="/addservices" className="menu-link">
                                            <i className="menu-icon tf-icons fas fa-check-circle"></i>
                                            <div data-i18n="Without menu">Add Services</div>
                                        </Link>
                                    </li>
                                )}
                                <li className={`menu-item ${location.pathname === '/serviceslist' || window.location.pathname.includes('/actionservice') ? 'active' : ''
                                    }`}>
                                    <Link to="/serviceslist" className="menu-link">
                                        <i className="menu-icon tf-icons ti ti-menu-2"></i>
                                        <div data-i18n="Vertical">Services List</div>
                                    </Link>
                                </li>
                                
                                <li className={`menu-item ${location.pathname === '/servicecategory' || window.location.pathname.includes('/actionservicecategory') ? 'active' : ''
                                    }`}>
                                    <Link to="/servicecategory" className="menu-link">
                                        <i className="menu-icon tf-icons ti ti-menu-2"></i>
                                        <div data-i18n="Vertical">ServiceCategory List</div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )}

                    {/* Extra Options */}
                    {rolePermissions['extraoption'] && (rolePermissions['extraoption'].read === 'yes' || rolePermissions['extraoption'].write === 'yes') && (
                        <li className={`menu-item ${isActiveMenu('#', ['/extraoptionslist']) || window.location.pathname.includes('/actionextra') ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter('extraoptionDropdown')}
                            onMouseLeave={handleMouseLeave}
                        >
                            {/* <Link to="#" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ti ti-list"></i>
                                <div data-i18n="Layouts">Extra Options</div>
                            </Link> */}
                            <Link to="./extraoptionslist" className="menu-link">
                            <i className="menu-icon tf-icons ti ti-list"></i>
                                <div data-i18n="Vertical">Extra Options</div>
                            </Link>
                            {/* <ul className="menu-sub"
                                style={{ display: activeDropdown === 'extraoptionDropdown' ? 'block' : 'none' }}
                            >
                                <li className={`menu-item ${location.pathname === '/extraoptionslist' || window.location.pathname.includes('/actionextra') ? 'active' : ''}`}>
                                    <Link to="./extraoptionslist" className="menu-link">
                                        <i className="menu-icon tf-icons ti ti-menu-2"></i>
                                        <div data-i18n="Vertical">ExtraOptions List</div>
                                    </Link>
                                </li>
                            </ul> */}
                        </li>
                    )}

                    {(rolePermissions['car'] && (rolePermissions['car'].read === 'yes' || rolePermissions['car'].write === 'yes')) ||
                        (rolePermissions['carcategory'] && (rolePermissions['carcategory'].read === 'yes' || rolePermissions['carcategory'].write === 'yes')) ? (
                        <li className={`menu-item ${isActiveMenu('#', ['/carcategory', '/carlist']) || window.location.pathname.includes('/actioncarlist') || window.location.pathname.includes('/actioncarcategory') ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter('carDropdown')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="#" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ti ti-car"></i>
                                <div data-i18n="Layouts">Car</div>
                            </Link>
                            <ul className="menu-sub" style={{ display: activeDropdown === 'carDropdown' ? 'block' : 'none' }}>
                                {rolePermissions['car'] && (rolePermissions['car'].read === 'yes' || rolePermissions['car'].write === 'yes') && (
                                    <li className={`menu-item ${location.pathname === '/carlist' || window.location.pathname.includes('/actioncarlist') ? 'active' : ''}`}>
                                        <Link to="./carlist" className="menu-link">
                                            <i className="menu-icon tf-icons ti ti-list"></i>
                                            <div data-i18n="Without menu">Car List</div>
                                        </Link>
                                    </li>
                                )}
                                {rolePermissions['carcategory'] && (rolePermissions['carcategory'].read === 'yes' || rolePermissions['carcategory'].write === 'yes') && (
                                    <li className={`menu-item ${location.pathname === '/carcategory' || window.location.pathname.includes('/actioncarcategory') ? 'active' : ''}`}>
                                        <Link to="./carcategory" className="menu-link">
                                            <i className="menu-icon ti ti-list"></i>
                                            <div data-i18n="Vertical">Car Category List</div>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    ) : null}


                    {/* Taxi */}
                    {/* {(rolePermissions['offerinfo'] && (rolePermissions['offerinfo'].read === 'yes' || rolePermissions['offerinfo'].write === 'yes')) ||
                        (rolePermissions['welcome'] && (rolePermissions['welcome'].read === 'yes' || rolePermissions['welcome'].write === 'yes')) ? (
                        <li className={`menu-item ${isActiveMenu('#', ['/offerinfo', '/welcomemessage']) || window.location.pathname.includes('/actionofferinfo') || window.location.pathname.includes('/actionwelcome') ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter('taxiDropdown')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="#" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ti ti-home"></i>
                                <div data-i18n="Layouts">Taxi</div>
                            </Link>
                            <ul className="menu-sub"
                                style={{ display: activeDropdown === 'taxiDropdown' ? 'block' : 'none' }}
                            >
                                {rolePermissions['offerinfo'] && (rolePermissions['offerinfo'].read === 'yes' || rolePermissions['offerinfo'].write === 'yes') && (
                                    <li className={`menu-item ${location.pathname === '/offerinfo' || window.location.pathname.includes('/actionofferinfo') ? 'active' : ''}`}>
                                        <Link to="./offerinfo" className="menu-link">
                                            <i className="menu-icon tf-icons   "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-gift-card"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" /><path d="M7 16l3 -3l3 3" /><path d="M8 13c-.789 0 -2 -.672 -2 -1.5s.711 -1.5 1.5 -1.5c1.128 -.02 2.077 1.17 2.5 3c.423 -1.83 1.372 -3.02 2.5 -3c.789 0 1.5 .672 1.5 1.5s-1.211 1.5 -2 1.5h-4z" /></svg></i>
                                            <div data-i18n="Without menu">Offer Info</div>
                                        </Link>
                                    </li>
                                )}
                                {rolePermissions['welcome'] && (rolePermissions['welcome'].read === 'yes' || rolePermissions['welcome'].write === 'yes') && (
                                    <li className={`menu-item ${location.pathname === '/welcomemessage' || window.location.pathname.includes('/actionwelcome') ? 'active' : ''}`}>
                                        <Link to="./welcomemessage" className="menu-link" >
                                            <i className="menu-icon "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-messages"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" /><path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" /></svg></i>
                                            <div data-i18n="Vertical">Welcome Message</div>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    ) : null} */}

                    {/* Central Settings */}
                    {rolePermissions['centralSetting'] && (rolePermissions['centralSetting'].read === 'yes' || rolePermissions['centralSetting'].write === 'yes') && (
                        <li className={`menu-item ${isActiveMenu('#', ['/timesetting', '/currency']) ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter('settingDropdown')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="#" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons fa-regular fa-building"></i>
                                <div data-i18n="Layouts">Central Setting</div>
                            </Link>
                            <ul className="menu-sub"
                                style={{ display: activeDropdown === 'settingDropdown' ? 'block' : 'none' }}
                            >
                                <li className={`menu-item ${location.pathname === '/timesetting' ? 'active' : ''}`}>
                                    <Link to="./timesetting" className="menu-link">
                                        <i className="menu-icon tf-icons fas fa-list-alt"></i>
                                        <div data-i18n="Without menu">Time Setting</div>
                                    </Link>
                                </li>
                                <li className={`menu-item ${location.pathname === '/currency' ? 'active' : ''}`}>
                                    <Link to="./currency" className="menu-link">
                                        <i className="menu-icon tf-icons fas fa-list-alt"></i>
                                        <div data-i18n="Vertical">Currency</div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )}

                    {/* Users */}
                    {rolePermissions['Users'] && (rolePermissions['Users'].read === 'yes' || rolePermissions['Users'].write === 'yes') && (
                        <li className={`menu-item ${isActiveMenu('#', ['/userlist', '/adduser']) || window.location.pathname.includes('/edituser') ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter('usersDropdown')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="#" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ti ti-users"></i>
                                <div data-i18n="Layouts">Users</div>
                            </Link>
                            <ul className="menu-sub"
                                style={{ display: activeDropdown === 'usersDropdown' ? 'block' : 'none' }}
                            >
                                <li className={`menu-item ${location.pathname === '/userlist' || window.location.pathname.includes('/edituser') ? 'active' : ''}`}>
                                    <Link to="./userlist" className="menu-link">
                                        <i className="menu-icon tf-icons ti ti-list"></i>
                                        <div data-i18n="Without menu">User List</div>
                                    </Link>
                                </li>
                                <li className={`menu-item ${location.pathname === '/adduser' ? 'active' : ''}`}>
                                    <Link to="./adduser" className="menu-link">
                                        <i className="menu-icon ti ti-list"></i>
                                        <div data-i18n="Vertical">Add Users</div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    )}
                    {rolePermissions['MetaTags'] && (rolePermissions['MetaTags'].read === 'yes' || rolePermissions['MetaTags'].write === 'yes') && (
                        <li className={`menu-item ${isActiveMenu('#', ['/metatags']) || window.location.pathname.includes('/action_metatags') ? 'active' : ''}`}
                            onMouseEnter={() => handleMouseEnter('seoSetting')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="#" className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons fa-regular fa-building"></i>
                                <div data-i18n="Layouts">SEO Setting</div>
                            </Link>
                            <ul className="menu-sub"
                                style={{ display: activeDropdown === 'seoSetting' ? 'block' : 'none' }}
                            >
                                <li className={`menu-item ${location.pathname === '/metatags' || window.location.pathname.includes('/action_metatags') ? 'active' : ''}`}>
                                    <Link to="./metatags" className="menu-link">
                                        <i className="menu-icon tf-icons fas fa-list-alt"></i>
                                        <div data-i18n="Without menu">MetaTags</div>
                                    </Link>
                                </li>

                            </ul>
                        </li>
                    )}

                    {rolePermissions['Booking'] && (rolePermissions['Booking'].read === 'yes' || rolePermissions['Booking'].write === 'yes') && (
                        <li className={`menu-item ${location.pathname === '/bookinglist' ? 'active' : ''}`}>
                            <Link to="./bookinglist" className="menu-link">
                                <i className="menu-icon tf-icons ti ti-smart-home"></i>
                                <div data-i18n="Dashboards">Booking</div>
                            </Link>
                        </li>
                    )}
  {rolePermissions['Customer'] && (rolePermissions['Customer'].read === 'yes' || rolePermissions['Customer'].write === 'yes') && (
                        <li className={`menu-item ${location.pathname === '/customerlist' ? 'active' : ''}`}>
                            <Link to="./customerlist" className="menu-link">
                                <i className="menu-icon tf-icons ti ti-user"></i>
                                <div data-i18n="Dashboards">Customer</div>
                            </Link>
                        </li>
                  )} 

                </ul>
            </div>
        </aside>
    );
};

export default AsideMenu;

