import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BookingBox from './Pages/BookingBox';
import ServiceIndex from './Pages/ServiceIndex';
import CarlistIndex from './Pages/CarlistIndex';
import TourismIndex from './Pages/TourismIndex';
import CarRentalIndex from './Pages/CarRentalIndex';
import AppIndex from './Pages/AppIndex';
import FooterContent from './Pages/FooterContent';
import LoginPage from './Pages/LoginPage';
import VerifyOTPPage from './Pages/VerifyOTPPage';
import PasswordPage from './Pages/PasswordPage';
import BookingSummaryPage from './Pages/BookingSummaryPage';
import ContactUs from './Pages/ContactUs';
import RegisterPage from './Pages/RegisterPage';
import HeaderMenu from './Pages/HeaderMenu';
import MyAccountMenu from './Pages/MyAccountMenu';
import ViewMoreTitle from './Pages/ViewMoreTitle';
import { Helmet } from 'react-helmet';
import HomePage from './Pages/HomePage';
import HowToWorks from './Pages/HowToWorks';
import Partners from './Pages/Partners ';
import { ToastContainer } from 'react-toastify';
import SocialMedia from './Pages/SocialMedia';

const StaticContent = () => {
    const tabs = [
        { id: 'booking-tab', label: 'Booking Box', component: <BookingBox /> },
        { id: 'home-index-tab', label: 'Home Page', component: <HomePage /> },
        { id: 'parnters-index-tab', label: 'Partners', component: <Partners /> },
        { id: 'works-index-tab', label: 'HowToWorks Page', component: <HowToWorks /> },
        { id: 'service-index-tab', label: 'Service Index', component: <ServiceIndex /> },
        { id: 'carlist-index-tab', label: 'Carlist Index', component: <CarlistIndex /> },
        // { id: 'tourism-index-tab', label: 'Tourism Index', component: <TourismIndex /> },
        // { id: 'carrental-index-tab', label: 'CarRental Index', component: <CarRentalIndex /> },
        // { id: 'app-index-tab', label: 'APP Index', component: <AppIndex /> },
        { id: 'footercontent-index-tab', label: 'Footer Content', component: <FooterContent /> },
        { id: 'loginpage-index-tab', label: 'Login Page', component: <LoginPage /> },
        { id: 'verifyotppage-index-tab', label: 'Verify OTP Page', component: <VerifyOTPPage /> },
        { id: 'password-index-tab', label: 'Password Page', component: <PasswordPage /> },
        { id: 'bookingsummary-index-tab', label: 'Booking Summary Page', component: <BookingSummaryPage /> },
        //{ id: 'contactus-index-tab', label: 'ContactUs', component: <ContactUs /> },
        { id: 'registerpage-index-tab', label: 'Register Page', component: <RegisterPage /> },
        { id: 'headermenu-index-tab', label: 'Header Menu', component: <HeaderMenu /> },
        { id: 'myaccountmenu-index-tab', label: 'MyAccount Menu', component: <MyAccountMenu /> },
        { id: 'socialmedia-index-tab', label: 'SocialMedia', component: <SocialMedia /> }
    ];

    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <div className="container-xl my-4">
            <ToastContainer/>
            <Helmet>
                <title>Static Content</title>
                <meta name="description" content="This is the static content page of the application" />
            </Helmet>
            <div className="row">
                <div className="col-md-6">
                    <h4>Static Content</h4>
                </div>
                <div className="col-md-6">
                    <div className="breadcumbtext">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="#"> Back </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="#">StaticContent</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="row row-cards">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header table-light border-0">
                            <div className="d-flex align-items-center">
                                <ul className="nav  table-light flex-grow-1" id="myTab" role="tablist">
                                    {tabs.map((tab) => (
                                        <li key={tab.id} className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link-two ${activeTab === tab.id ? 'active' : ''}`}
                                                onClick={() => handleTabClick(tab.id)}
                                            >
                                                {tab.label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="tab-content">
                            {tabs.map((tab) => (
                                <div
                                    key={tab.id}
                                    className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
                                    id={`form-tabs-${tab.id}`}
                                    role="tabpanel"
                                    aria-labelledby={tab.id}
                                >
                                    {tab.component}
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaticContent;
