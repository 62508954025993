import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableTitle from './TableTitle';
import { ToastContainer } from 'react-toastify';


const TableMain = ({
    title,
    addLink,
    columns,
    data,
    setData,
    selectedRows,
    handleRowSelect,
    setSelectedRows,
    currentPage,
    recordsPerPage,
    totalRecords,
    onPageChange,
    handleRowAction,
    filteredData,
    handleSort,
    getSortIndicator,
    isBookingListPage,
    isCustomerPage,
    canEdit,
    checkbox,
    RecentLists
}) => {
    const handleSelectAll = () => {
        const allSelected = selectedRows.every(Boolean);
        setSelectedRows(Array(data.length).fill(!allSelected));
    };

    return (
        <div className="card mt-3">
            <ToastContainer />
            <div className="card-datatable table-responsive pt-0">
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div className="card-header flex-column flex-md-row">
                        <div className="head-label ">
                            <TableTitle title={title} addLink={addLink} RecentLists={RecentLists} />
                            <div className="card-datatable text-nowrap mt-3">
                                <table className="table table-bordered">
                                    <TableHeader
                                        columns={columns}
                                        handleSelectAll={handleSelectAll}
                                        selectedRows={selectedRows}
                                        isBookingListPage={isBookingListPage}
                                        handleSort={handleSort}
                                        getSortIndicator={getSortIndicator}
                                        canEdit={canEdit}
                                        checkbox={checkbox}
                                        isCustomerPage={isCustomerPage}
                                        data={data}
                                       
                                    />
                                    <TableBody
                                        data={data}
                                        columns={columns}
                                        selectedRows={selectedRows}
                                        handleRowSelect={handleRowSelect}
                                        filteredData={filteredData}
                                        handleRowAction={handleRowAction}
                                        isBookingListPage={isBookingListPage}
                                        isCustomerPage={isCustomerPage}
                                        canEdit={canEdit}
                                        checkbox={checkbox}
                                    />
                                </table>
                                {!RecentLists ===true&&
                                <TableFooter
                                    totalRecords={totalRecords}
                                    currentPage={currentPage}
                                    recordsPerPage={recordsPerPage}
                                    onPageChange={onPageChange}
                                />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableMain;
