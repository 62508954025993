import React, { useState,useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../Constant/Constant'
import CryptoJS from 'crypto-js';

// Validation Schema
const DisplayingErrorMessagesSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    // quantity: Yup.number().typeError('quantity must be a number').required('Quantity is required'),
    price: Yup.number().typeError('Price must be a number').required('Price is required'),
    // duration: Yup.string().required('Duration is required'),
    // description: Yup.string().required('Description is required'),
    category: Yup.string().required('Category is required')  // Added category validation
});

// ModalExtraOptions Component
const ModalExtraOptions = ({ refreshData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);


    // Initial form values
    const initialValues = {
        title: "",
        quantity: "",
        price: "",
        duration: null,
        description: "",
        category: ""  // Added category to initial values
    };

    // Sample categories, replace with your actual categories
    const categories = [
        { value: '', label: 'Select Category' },
        { value: 'select', label: 'Select Items' },
        { value: 'quantity', label: 'Quantity Items' }
    ];

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
        try {
            const formData = {
                ...values,
                language_id:languageId
            };
    
            // Send the form data to the server
            await axios.post(`${BASE_URL}extraoption/add`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            toast.dismiss();
            toast.success('Form submitted successfully!', {
                autoClose: 1500,
            });
            resetForm();
            setModalOpen(false); // Close modal after submission
            refreshData(); // Refresh table data
        } catch (error) {
            toast.dismiss();
            
            // Extract and display the backend message if it exists, otherwise show a generic error message
            const errorMessage = error.response?.data?.message || 'Error submitting form';
            toast.error(errorMessage, {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <Link type="button" className="btn btn-primary waves-effect waves-light" onClick={() => setModalOpen(true)}>
                <i className="fa fa-plus me-2"></i>Add
            </Link>

            {modalOpen && (
                <>
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" aria-hidden={!modalOpen}>
                        <ToastContainer />
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content" style={{ backgroundColor: 'white' }}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="modalCentertitle">Add</h5>
                                    <button type="button" className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close"></button>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={DisplayingErrorMessagesSchema}
                                    enableReinitialize={true} // Ensures the form is reinitialized if initialValues change
                                    onSubmit={handleSubmit}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className="modal-body">
                                                <div className='row g-3'>
                                            <div className="col-6 mb-3">
                                                    <label htmlFor="category" className="form-label">Category<span className='text-danger px-1 fs-5'>*</span></label>
                                                    <Field
                                                        id="category"
                                                        name="category"
                                                        as="select"
                                                        className={`form-select ${errors.category && touched.category ? 'is-invalid' : ''}`}
                                                    >
                                                        {categories.map(cat => (
                                                            <option key={cat.value} value={cat.value}>{cat.label}</option>
                                                        ))}
                                                    </Field>
                                                    {errors.category && touched.category && (
                                                        <div className="invalid-feedback">{errors.category}</div>
                                                    )}
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <label htmlFor="title" className="form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                                                    <Field
                                                        id="title"
                                                        name="title"
                                                        type="text"
                                                        className={`form-control ${errors.title && touched.title ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.title && touched.title && (
                                                        <div className="invalid-feedback">{errors.title}</div>
                                                    )}
                                                </div>
                                                </div>
                                                <div className='row g-3'>
                                                <div className="col mb-3">
                                                    <label htmlFor="price" className="form-label">Price<span className='text-danger px-1 fs-5'>*</span></label>
                                                    <Field
                                                        id="price"
                                                        name="price"
                                                      type="number"
min="0"
                                                        className={`form-control ${errors.price && touched.price ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.price && touched.price && (
                                                        <div className="invalid-feedback">{errors.price}</div>
                                                    )}
                                                </div>
                                                {/* <div className="col mb-3">
                                                    <label htmlFor="duration" className="form-label">Duration</label>
                                                    <Field
                                                        id="duration"
                                                        name="duration"
                                                        type="text"
                                                        className={`form-control ${errors.duration && touched.duration ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.duration && touched.duration && (
                                                        <div className="invalid-feedback">{errors.duration}</div>
                                                    )}
                                                </div> */}
                                                <div className="col mb-3">
                                                    <label htmlFor="quantity" className="form-label">Quantity</label>
                                                    <Field
                                                        id="quantity"
                                                        name="quantity"
                                                      type="number"
min="0"
                                                        className={`form-control ${errors.quantity && touched.quantity ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.quantity && touched.quantity && (
                                                        <div className="invalid-feedback">{errors.quantity}</div>
                                                    )}
                                                </div>
                                                </div>
                                                <div className='row g-3'>
                                                
                                                <div className="col mb-3">
                                                    <label htmlFor="description" className="form-label">Description</label>
                                                    <Field
                                                        id="description"
                                                        name="description"
                                                        type="text"
                                                        className={`form-control ${errors.description && touched.description ? 'is-invalid' : ''}`}
                                                    />
                                                    {errors.description && touched.description && (
                                                        <div className="invalid-feedback">{errors.description}</div>
                                                    )}
                                                </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-label-secondary" onClick={() => setModalOpen(false)}>Close</button>
                                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                                    {loading ? 'Adding...' : 'Add'}
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>
                </>
            )}
        </>
    );
};

export default ModalExtraOptions;
