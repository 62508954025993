
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RecentList = ({ firstFiveMessages }) => {
    const [data, setData] = useState(firstFiveMessages || []); // Initialize with the passed array
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Update data whenever `firstFiveMessages` changes
        setData(firstFiveMessages || []);
    }, [firstFiveMessages]);

    return (
        <div className="container-12 mt-5 recentTem" >
              <div className="card mt-3">
            <div className="card-datatable table-responsive pt-0">
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div className="card-header flex-column flex-md-row">
                        <div className="head-label ">
                            <h4>Recent Bookings</h4>
                            <div className="card-datatable text-nowrap mt-3">
                                <table className="table table-bordered">
                                <thead className="table-light ">
                                <tr>
                                    <th>Booking ID</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>From Location</th>
                                    <th>To Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((booking, index) => (
                                    <tr key={booking.id}>
                                        <td>{booking.booking_id}</td>
                                        <td>{new Date(booking.date).toLocaleDateString('en-GB')} </td>
                                        <td> {booking.time.slice(0, 5)}</td>
                                        <td>{booking.from_location}</td>
                                        <td>{booking.to_location}</td>
                                    </tr>
                                ))}
                            </tbody>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

RecentList.propTypes = {
    firstFiveMessages: PropTypes.array.isRequired, // Expect an array of bookings
};

export default RecentList;




