import React, { useState, useEffect, useRef } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { MdOutlineCancel } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, BACKENDTOKEN, SELECTEDLANGUAGEID,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2  } from '../../../Constant/Constant';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';

const AddCar = () => {
    const [carCategories, setCarCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const imageInputRef = useRef(null);
    const previewImageInputRef = useRef(null);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);
    const fetchCarCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}carcategory/get`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setCarCategories(response.data);
        } catch (error) {
            console.error('Error fetching car categories:', error);
        }
    };

    useEffect(() => {
        if (token ) {
            fetchCarCategories();
        }
    }, [token]);
  

    // Yup validation schema
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        image: Yup.mixed().required('Image is required').test(
            'fileFormat',
            'Unsupported format',
            (value) => !value || ['image/jpeg', 'image/png', 'image/gif'].includes(value?.type)
        ).test(
            'fileSize',
            'File size should be less than 1MB',
            (value) => !value || value.size < 1024 * 1024
        ),
        preview_image: Yup.mixed().required('Preview Image is required').test(
            'fileFormat',
            'Unsupported format',
            (value) => !value || ['image/jpeg', 'image/png', 'image/gif'].includes(value?.type)
        ).test(
            'fileSize',
            'File size should be less than 1MB',
            (value) => !value || value.size < 1024 * 1024
        ),
        //short_description: Yup.string().required('Short description is required'),
        // Language: Yup.string().required('Language is required'),
        seats: Yup.number().required('Seats are required').positive('Seats must be positive').integer(),
        luggage: Yup.string().required('Luggage description is required'),
        start_price: Yup.number().required('Start price is required').positive('Must be positive'),
        day_price: Yup.number().required('Day price is required').positive('Must be positive'),
        night_price: Yup.number().required('Night price is required').positive('Must be positive'),
        //discount: Yup.number().required('Discount is required').min(0).max(100),
        carcategory_id: Yup.string().required('Car category is required'),
        // points: Yup.array().of(Yup.object().shape({
        //     text: Yup.string().required('Point is required'),
        // })),
    });

    const initialValues = {
        title: '',
        image: null,
        short_description: '',
        preview_image: null,
        seats: '',
        luggage: '',
        start_price: '',
        day_price: '',
        night_price: '',
        discount: '',
        points: [{ text: '' }],
        description: '',
        carcategory_id: '',
    };

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
    
        // Define validation rules for images (minimum width 1000 pixels, no height validation)
        const imageValidations = [
            {
                file: values.preview_image,
                minWidth: 1000, // Minimum width only
                errorMessage: 'Preview image must have a minimum width of 1000 pixels.',
            },
            {
                file: values.image,
                minWidth: 1000, // Minimum width only
                errorMessage: 'Image must have a minimum width of 1000 pixels.',
            },
        ];
    
        // Validate each image based on the defined rules
        for (const { file, minWidth, errorMessage } of imageValidations) {
            // Log the file before proceeding to check its validity
            console.log('Checking file:', file); // Debugging log
    
            // Check if file is valid before proceeding
            if (!file || !(file instanceof File)) {
                console.error('Invalid file:', file); // Log the invalid file for debugging
                toast.dismiss();
                toast.error(`Invalid file for validation: ${errorMessage}`, { autoClose: 1500 });
                setLoading(false);
                return;
            }
    
            const isValid = await checkImageMinWidth(file, minWidth);
            console.log(`Validation result for ${errorMessage}:`, isValid); // Debugging log
    
            if (!isValid) {
                toast.dismiss();
                toast.error(errorMessage, { autoClose: 1500 });
                setLoading(false);
                return;
            }
        }
    
        // Prepare form data
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('short_description', values.short_description);
        formData.append('language_id', languageId);
        formData.append('seats', values.seats);
        formData.append('luggage', values.luggage);
        formData.append('start_price', values.start_price);
        formData.append('day_price', values.day_price);
        formData.append('night_price', values.night_price);
        formData.append('discount', values.discount);
        formData.append('description', values.description);
        formData.append('carcategory_id', values.carcategory_id);
        formData.append('points', JSON.stringify(values.points.map((point) => point.text)));
        formData.append('image', values.image);
        formData.append('preview_image', values.preview_image);
    
        try {
            const response = await axios.post(`${BASE_URL}car/add`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Car added successfully');
            resetForm();
            if (imageInputRef.current) {
                imageInputRef.current.value = ''; // Clear the Image input field
            }
            if (previewImageInputRef.current) {
                previewImageInputRef.current.value = ''; // Clear the Preview Image input field
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error submitting form';
            toast.dismiss();
            toast.error(errorMessage, {
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };
    

    const checkImageMinWidth = (file, minWidth) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
    
            img.onload = () => {
                const isValid = img.width >= minWidth; // Validate the minimum width
                resolve(isValid);
            };
    
            img.onerror = () => {
                console.error('Error loading image for validation.');
                resolve(false); // Mark as invalid if the image cannot be loaded
            };
        });
    };
    

    return (
        <div className="container-xl my-4">
            <ToastContainer />
            <div className="row">
                <div className="col-md-6">
                    <h4><span className="text-muted fw-light"> </span> Cars</h4>
                </div>
                <div className="col-md-6">
                    <div className="breadcumbtext">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-end">
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="../carlist">Back</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link className="footer-link fs-6" to="#">Add</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row row-cards">
                <div className="col-12">
                    <div className="card">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <div className="card-header" style={{ borderBottom: '1px solid #ccc', marginBottom: '2rem', paddingBottom: '1rem' }}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h5 className="card-title me-3">Add</h5>
                                            <div className="mb-3">
                                                <Field
                                                    as="select"
                                                    name="carcategory_id"
                                                    className="form-select"
                                                >
                                                    <option value="">Select Car Category</option>
                                                    {carCategories.map(category => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage name="carcategory_id" component="div" className="invalid-feedback" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row g-2 mb-3">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                                                    <Field name="title" type="text" className="form-control" />
                                                    <ErrorMessage name="title" component="div" className="invalid-feedback" />
                                                </div>
                                            </div>

                                            <div className="col-sm-3">
                                                <label className="form-label">Passager Count <span className='text-danger px-1 fs-5'>*</span> </label>
                                                <Field name="seats" type="number" className="form-control" />
                                                <ErrorMessage name="seats" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">Luggage Count<span className='text-danger px-1 fs-5'>*</span></label>
                                                <Field name="luggage" type="number" className="form-control" />
                                                <ErrorMessage name="luggage" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>

                                        <div className="row g-2 mb-3">
                                            <div className="col-sm-6">
                                                <label className="form-label">Start Price<span className='text-danger px-1 fs-5'>*</span></label>
                                                <Field name="start_price" type="number" className="form-control" />
                                                <ErrorMessage name="start_price" component="div" className="invalid-feedback" />

                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">Day Price<span className='text-danger px-1 fs-5'>*</span></label>
                                                <Field name="day_price" type="number" className="form-control" />
                                                <ErrorMessage name="day_price" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="col-sm-3">
                                                <label className="form-label">Night Price<span className='text-danger px-1 fs-5'>*</span></label>
                                                <Field name="night_price" type="number" className="form-control" />
                                                <ErrorMessage name="night_price" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="row g-2 mb-3">
                                            <div className="col-sm-6">
                                                <label className="form-label">Discount</label>
                                                <Field name="discount" type="number" className="form-control" />
                                                <ErrorMessage name="discount" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label">Short Description</label>
                                                <Field name="short_description" type="text" className="form-control" />
                                                <ErrorMessage name="short_description" component="div" className="invalid-feedback" />
                                            </div>
                                        </div>
                                        <div className="row g-2 mb-3">
                                            <div className="col-sm-6">
                                                <label className="form-label">Image (1920 x 760)<span className='text-danger px-1 fs-5'>*</span></label>
                                                <input
                                                    name="image"
                                                    type="file"
                                                    ref={imageInputRef}
                                                    accept="image/jpeg, image/png, image/gif"
                                                    className="form-control"
                                                    onChange={(event) => setFieldValue('image', event.currentTarget.files[0])}
                                                />
                                                <ErrorMessage name="image" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="form-label">Preview image  (1530 x 711 )<span className='text-danger px-1 fs-5'>*</span></label>
                                                <input
                                                    name="preview_image"
                                                    ref={previewImageInputRef}
                                                    type="file"
                                                    accept="image/jpeg, image/png, image/gif"
                                                    className="form-control"
                                                    onChange={(event) => setFieldValue('preview_image', event.currentTarget.files[0])}
                                                />
                                                <ErrorMessage name="preview_image" component="div" className="invalid-feedback" />

                                            </div>
                                        </div>
                                        <div className="row g-2 mb-3">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Points</label>
                                                    <FieldArray name="points">
                                                        {({ push, remove }) => (
                                                            <div>
                                                                {values.points.map((point, index) => (
                                                                    <div key={index} className="input-group mb-3">
                                                                        <Field
                                                                            name={`points[${index}].text`}
                                                                            type="text"
                                                                            className="form-control"
                                                                        />
                                                                        <ErrorMessage name={`points[${index}].text`} component="div" className="invalid-feedback" />
                                                                        {index !== 0 && (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-label-secondary waves-effect waves-light"
                                                                                onClick={() => remove(index)}
                                                                            >
                                                                                <MdOutlineCancel />
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-sm"
                                                                    onClick={() => push({ text: '' })}
                                                                >
                                                                    <IoAddCircleOutline style={{ fontSize: '16px' }} />
                                                                </button>
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Description</label>
                                                    <ReactQuill
                                                        value={values.description}
                                                        onChange={(value) => setFieldValue('description', value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 d-flex justify-content-end align-items-center">
                                                <button
                                                    type="submit"
                                                    className={`btn btn-primary ${loading ? 'disabled' : ''}`}
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Adding...' : 'Add'}
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div >
    );
};

export default AddCar;
