import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../../../Constant/Constant';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
const SocialMedia = () => {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({
        SocialMediaTitle: '',
        FacebookLink: '',
        TwitterLink: '',
        InstagramLink: '',
        LinkedInLink: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [isDataExists, setIsDataExists] = useState(false);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
    const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
    const [isBookingBoxData, setIsBookingBoxData] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);

  
    useEffect(() => {
      if (permissions) {
        try {
          const modules = JSON.parse(permissions);
          const legalcontent = modules.find(module => module.rolename === 'staticcontent');
          if (legalcontent) {
            setRolePermissions(legalcontent.permissions);
            setIsBookingBoxData(legalcontent.permissions.write === 'no');
           
          }
        } catch (error) {
          console.error('Failed to parse permissions:', error);
        }
      }
    }, [permissions]);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}staticcontent/get?language_id=${languageId}`,{
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data && response.data.socialmedia) {
                const parsedData = JSON.parse(response.data.socialmedia); 
                setFormValues(parsedData); 
              
                setIsDataExists(true);
                
            } else {
                setIsDataExists(false);
            }
        } catch (error) {
            setIsEditMode(true);
        }
       
    };
    useEffect(() => {
        if (token && languageId) {
            fetchData();
        }
    }, [token, languageId]);
   

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        if (rolePermissions.write === 'no') {
            toast.dismiss();
            toast.error('You do not have permission to update data.', {
                toastId: '1',
              autoClose: 1500,
            });
            return;
          }
        e.preventDefault();
        setLoading(true);

        try {
            const payload = {
                socialmedia: formValues,
                language_id: languageId
            };

            const url = `${BASE_URL}staticcontent/socialmedia`;

            const response = await axios.post(url, payload,{
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.message === 'socialmedia Process successfully!') {
                setIsDataExists(true);
                toast.dismiss()
                toast.success('Data Process Successfully', {
                    toastId: '2',
                    autoClose: 1500,
                });
            } else {
                toast.dismiss()
                toast.error('Unexpected response from the server.', {
                    toastId: '3',
                    autoClose: 1500,
                });
            }

            setIsEditMode(false);
        } catch (error) {
            toast.dismiss()
            toast.error('Error saving data.', {
                toastId: '4',
                autoClose: 1500,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className="row g-3" onSubmit={handleSubmit}>
        
            {/* Form Fields */}
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">SocialMedia Title</label>
                    <input
                        name="SocialMediaTitle"
                        type="text"
                        className="form-control"
                        value={formValues.SocialMediaTitle}
                        onChange={handleChange}
                        disabled={isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Facebook Link</label>
                    <input
                        name="FacebookLink"
                        type="text"
                        className="form-control"
                        value={formValues.FacebookLink}
                        onChange={handleChange}
                        disabled={isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Twitter Link</label>
                    <input
                        name="TwitterLink"
                        type="text"
                        className="form-control"
                        value={formValues.TwitterLink}
                        onChange={handleChange}
                        disabled={isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">Instagram Link</label>
                    <input
                        name="InstagramLink"
                        type="text"
                        className="form-control"
                        value={formValues.InstagramLink}
                        onChange={handleChange}
                        disabled={isBookingBoxData}
                    />
                </div>
            </div>
            <div className="col-md-4">
                <div className="mb-3">
                    <label className="form-label">LinkedIn Link</label>
                    <input
                        name="LinkedInLink"
                        type="text"
                        className="form-control"
                        value={formValues.LinkedInLink}
                        onChange={handleChange}
                        disabled={isBookingBoxData}
                    />
                </div>
            </div>
           

            <div className="card-footer text-end">
                {rolePermissions.write === 'yes' && 
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : (isDataExists ? 'Update' : 'Add')}
                    </button>
}
                </div>
        </form>
    );
};

export default SocialMedia;
