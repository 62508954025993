import React, { useState ,useEffect} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {BASE_URL,CRYPTOJS_ENCRYPTION_KEY,CRYPTOJS_ENCRYPTION_KEY_2 } from '../../../Constant/Constant'
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
// Validation Schema
const DisplayingErrorMessagesSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
});

const ModalServicesCategory = ({ refreshData }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [languageId, setLanguageId] = useState(null);
    
    // Function to decrypt tokens
    const decryptToken = (encryptedToken, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
            const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedToken ? decryptedToken : null;
        } catch (error) {
            console.error('Error decrypting token:', error);
            return null;
        }
    };
    
    // Fetch token from localStorage and decrypt it
    const fetchBackendToken = async () => {
        const encryptedBackendToken = localStorage.getItem('BackendToken');
        if (encryptedBackendToken) {
            const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
            setToken(decryptedToken); // Set token to state
        } else {
            console.warn('No BackendToken found in localStorage.');
        }
    };
    
    // Fetch language from localStorage and decrypt it
    const fetchLanguage = async () => {
        const encryptedLanguage = localStorage.getItem('selectedLanguageId');
        if (encryptedLanguage) {
            const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
            if (decryptedLanguage) {
                setLanguageId(decryptedLanguage); 
            } else {
                console.error("Failed to decrypt languageId.");
            }
        } else {
            console.warn('No selectedLanguageId found in localStorage.');
        }
    };
        useEffect(() => {
        fetchBackendToken(); // Fetch and set token
        fetchLanguage(); // Fetch and set language ID
    }, []);

    const initialValues = {
        name: '',
    };

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true);
    
        try {
            // Create the JSON payload
            const payload = {
                name: values.name,
                language_id: languageId,
            };
    
            // Log the payload for debugging
            console.log('Submitting payload:', payload);
    
            // Send JSON data to the backend
            await axios.post(`${BASE_URL}servicecategory/add`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            setLoading(false);
            refreshData();
            setModalOpen(false);
            resetForm();
        } catch (error) {
            console.error('Error during form submission:', error.response?.data || error.message);
            const errorMessage = error.response?.data?.message || 'Error submitting form';
            toast.error(errorMessage, {
                autoClose: 1500,
            });
            setLoading(false);
        }
    };
    

    return (
        <>
            <Link type="button" className="btn btn-primary waves-effect waves-light" onClick={() => setModalOpen(true)}>
                <i className="fa fa-plus me-2"></i>Add 
            </Link>

            <div className={`modal fade ${modalOpen ? 'show' : ''}`} style={{ display: modalOpen ? 'block' : 'none' }} tabIndex="-1" aria-hidden={!modalOpen}>
                <div className=" modal-dialog-centered modal-dialog modal-sm" role="document">
                    <div className="modal-content" style={{ backgroundColor: 'white' }}>
                        <div className="modal-header">
                            <h5 className="modal-name" id="modalCentername">Add</h5>
                            <button type="button" className="btn-close" onClick={() => setModalOpen(false)} aria-label="Close"></button>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={DisplayingErrorMessagesSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ setFieldValue }) => (
                                <Form>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col mb-3">
                                                <div className="row g-2">
                                                    <div className="col mb-3">
                                                        <label htmlFor="name" className="form-label">Name<span className='text-danger px-1 fs-5'>*</span>
                                                        </label>
                                                        <Field
                                                            name="name"
                                                            type="text"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-label-secondary" onClick={() => setModalOpen(false)}>Close</button>
                                        <button type="submit" className="btn btn-primary" disabled={loading}>
                                            {loading ? 'Adding...' : 'Add'}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {modalOpen && <div className="modal-backdrop fade show" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}></div>}
        </>
    );
};

export default ModalServicesCategory;
