import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL,BACKENDTOKEN,CRYPTOJS_ENCRYPTION_KEY } from '../../Constant/Constant';
import '../../App.css';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
const EditUser = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [rolesData, setRolesData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [userTypes, setUserTypes] = useState([]);
    const permissions = useSelector(state => state.roles.permissions.user_modules);
const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
const [token, setToken] = useState(null);

// Function to decrypt tokens
const decryptToken = (encryptedToken, key) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
    const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedToken ? decryptedToken : null;
  } catch (error) {
    console.error('Error decrypting token:', error);
    return null;
  }
};

// Fetch token from localStorage and decrypt it
const fetchBackendToken = async () => {
  const encryptedBackendToken = localStorage.getItem('BackendToken');
  if (encryptedBackendToken) {
    const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
    setToken(decryptedToken); // Set token to state
  } else {
    console.warn('No BackendToken found in localStorage.');
  }
};


useEffect(() => {
  fetchBackendToken(); // Fetch and set token
}, []);


useEffect(() => {
    if (permissions) {
        try {
            const modules = JSON.parse(permissions);
            const Users = modules.find(module => module.rolename === 'Users');
            if (Users) {
                setRolePermissions(Users.permissions);
            }
        } catch (error) {
            console.error('Failed to parse permissions:', error);
        }
    }
}, [permissions]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${BASE_URL}user/permission`);
                const data = response.data[0];

                if (data.user_role) {
                    try {
                        const roles = JSON.parse(data.user_role);
                        if (Array.isArray(roles)) {
                            setUserTypes(roles);
                        } else {
                            toast.error('Failed to parse user roles data.', {
                                autoClose: 1500,
                            });
                        }
                    } catch (parseError) {
                        toast.error('Failed to parse user roles data.', {
                            autoClose: 1500,
                        });
                    }
                }
            } catch (error) {
                toast.error('Failed to fetch user data.', {
                    autoClose: 1500,
                });
            }
        };

        fetchUserData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}user/get/${id}`,{
                headers :{
                  'Authorization': `Bearer ${token}`
                }
              });
            setUserData(response.data);

            if (response.data.user_modules) {
                try {
                    const modules = JSON.parse(response.data.user_modules);
                    if (Array.isArray(modules)) {
                        setRolesData(modules);
                    }
                } catch (parseError) {
                    toast.error('Failed to parse modules data.', {
                        autoClose: 1500,
                    });
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                navigate('/no-page-found');
            } else {
                toast.error('Error fetching data.', {
                    autoClose: 1500,
                });
            }
        }
    };
    useEffect(() => {
        if (token ) {
            fetchData();
        }
    }, [token]);
    


    const handleEditClick = () => setIsEditMode(!isEditMode);

    const handleCheckboxChange = (roleIndex, permissionType) => {
        setRolesData((prevRolesData) =>
            prevRolesData.map((role, index) =>
                index === roleIndex
                    ? {
                        ...role,
                        permissions: {
                            ...role.permissions,
                            [permissionType]: role.permissions[permissionType] === 'yes' ? 'no' : 'yes',
                        },
                    }
                    : role
            )
        );
    };

    const handleSelectAllChange = () => {
        const newValue = !selectAll;
        setSelectAll(newValue);
        setRolesData((prevRolesData) =>
            prevRolesData.map((role) => ({
                ...role,
                permissions: {
                    read: newValue ? 'yes' : 'no',
                    write: newValue ? 'yes' : 'no',
                },
            }))
        );
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            if (rolePermissions.write === 'no') {
                toast.dismiss();
                toast.error('You do not have permission to update data.', {
                    autoClose: 1500,             
            });
                return;
            }
            const updatedModules = JSON.stringify(rolesData);
            const updatedUserData = {
                ...userData,
                user_modules: updatedModules,
            };
            await axios.put(`${BASE_URL}user/update/${id}`, updatedUserData,{
                headers :{
                  'Authorization': `Bearer ${token}`
                }
              });
            setIsEditMode(false);
            toast.success('Data updated successfully', {
                autoClose: 1500,
            });
        } catch (error) {
            toast.error('Error updating roles data: ' + error.message, {
                autoClose: 1500,
            });
        }
    };

    const handleCancelClick = () => {
        setIsEditMode(false);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const action = searchParams.get('action');
        setIsEditMode(action === 'edit');
    }, [location.search]);

    if (!userData) {
        return <p>Loading user data...</p>;
    }

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <ToastContainer/>
            <div className="row">
                <div className="col-md-6">
                    <h4>Users</h4>
                </div>
                <div className="col-md-6">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-end fs-3">
                            <li className="breadcrumb-item"><Link to="/userlist" className='fs-6'>Back</Link></li>
                            <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="card-title mb-0">{isEditMode ? 'Edit User' : 'View User'}</h5>

                </div>
                <div className="card-body">
                    <form onSubmit={handleSave}>
                        <div className='row g-3'>
                            {/* Other input fields */}
                            <div className="col-4 mb-3">
                                <label htmlFor="first_name" className="form-label">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="first_name"
                                    value={userData.first_name || ''}
                                    disabled={!isEditMode}
                                    onChange={(e) => setUserData({ ...userData, first_name: e.target.value })}
                                />
                            </div>
                            <div className="col-4 mb-3">
                                <label htmlFor="last_name" className="form-label">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="last_name"
                                    value={userData.last_name || ''}
                                    disabled={!isEditMode}
                                    onChange={(e) => setUserData({ ...userData, last_name: e.target.value })}
                                />
                            </div>
                            <div className="col-4 mb-3">
                                <label htmlFor="mobile_number" className="form-label">Mobile Number</label>
                                <input
                                  type="number"
min="0"
                                    className="form-control"
                                    id="mobile_number"
                                    value={userData.mobile_number || ''}
                                    disabled={!isEditMode}
                                    onChange={(e) => setUserData({ ...userData, mobile_number: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='row g-3'>
                            <div className="col-4 mb-3">
                                <label className="form-label">Role</label>
                                <select
                                    value={userData.role || ''}
                                    onChange={e => setUserData({ ...userData, role: e.target.value })}
                                    disabled={!isEditMode}
                                    className="form-select"
                                >
                                    <option value="">Select a role</option>
                                    {userTypes.map((role, index) => (
                                        <option key={index} value={role}>
                                            {role}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-4 mb-3">
                                <label htmlFor="email" className="form-label">Email</label>

                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={userData.email || ''}
                                    disabled={!isEditMode}
                                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                />

                            </div>

                        </div>

                        <div className="col-8 mb-3 ">
                            <label className="form-label ">User Modules</label>
                            <table className="table table-flush-spacing rounded">
                                <tbody className="border-1 rounded">
                                    <tr>
                                        <td className="text-nowrap fw-medium px-4">Modules</td>
                                        <td>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onChange={handleSelectAllChange}
                                                    disabled={!isEditMode}
                                                />
                                                <label className="form-check-label" htmlFor="selectAll">
                                                    Select All
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    {rolesData.map((role, index) => (
                                        <tr key={index}>
                                            <td className="text-nowrap fw-medium px-4 col-sm-6">{role.rolename}</td>
                                            <td>
                                                <div className="d-flex">
                                                    <div className="form-check me-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={role.permissions.read === 'yes'}
                                                            onChange={() => handleCheckboxChange(index, 'read')}
                                                            disabled={!isEditMode}
                                                        />
                                                        <label className="form-check-label">Read</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={role.permissions.write === 'yes'}
                                                            onChange={() => handleCheckboxChange(index, 'write')}
                                                            disabled={!isEditMode}
                                                        />
                                                        <label className="form-check-label">Write</label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                        {isEditMode && (
                            <div className="mt-3">
                                <button type="submit" className="btn btn-primary rounded me-3">Update</button>
                                <button className="btn btn-label-secondary rounded" onClick={handleCancelClick}>Cancel</button>
                            </div>
                        )}
                            {!isEditMode && rolePermissions.write === 'yes' && (
                                <button className="btn btn-primary rounded" onClick={handleEditClick}>
                                    Edit
                                </button>
                            )}
                           
                      
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditUser;



