import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the CSS for ReactQuill
import { MdOutlineCancel } from 'react-icons/md';
import { IoAddCircleOutline } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY } from '../../Constant/Constant';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';

const ActionServices = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();
  const [points, setPoints] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [message, setMessage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [previewImagePreview, setPreviewImagePreview] = useState('');

  const [ServiceCategories, setServiceCategories] = useState([]);
  // Get permissions from Redux store
  const permissions = useSelector(state => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
  const [token, setToken] = useState(null);

  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken ? decryptedToken : null;
    } catch (error) {
      console.error('Error decrypting token:', error);
      return null;
    }
  };

  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
    const encryptedBackendToken = localStorage.getItem('BackendToken');
    if (encryptedBackendToken) {
      const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
      setToken(decryptedToken); // Set token to state
    } else {
      console.warn('No BackendToken found in localStorage.');
    }
  };

  useEffect(() => {
    fetchBackendToken();
  }, []);

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    short_description: Yup.string().required('Short description is required'),
    service_category: Yup.string().required('Service category is required'),
    description: Yup.string().required('Description is required'),

    // Validation for new Image upload only
    Image: Yup.mixed().test(
      'fileRequired',
      'Image is required',
      (value) => {
        // Validate if a new file is uploaded or skip if an existing image is present
        if (value && value instanceof File) {
          return true; // New file is present
        }
        return !!formik.values.Image; // Return true if an existing image already exists (URL or string)
      }
    ).test(
      'fileFormat',
      'Unsupported format',
      (value) => {
        // Only validate the format if the value is a new File
        if (value && value instanceof File) {
          return ['image/jpeg', 'image/png', 'image/gif'].includes(value?.type);
        }
        return true; // Skip format validation for existing images
      }
    ).test(
      'fileSize',
      'File size should be less than 1MB',
      (value) => {
        // Only validate the file size if the value is a new File
        if (value && value instanceof File) {
          return value.size < 1024 * 1024; // Check file size
        }
        return true; // Skip size validation for existing images
      }
    ),

    // Validation for new preview_image upload only
    preview_image: Yup.mixed().test(
      'fileRequired',
      'Preview Image is required',
      (value) => {
        // Validate if a new file is uploaded or skip if an existing image is present
        if (value && value instanceof File) {
          return true; // New file is present
        }
        return !!formik.values.preview_image; // Return true if an existing preview image already exists (URL or string)
      }
    ).test(
      'fileFormat',
      'Unsupported format',
      (value) => {
        // Only validate the format if the value is a new File
        if (value && value instanceof File) {
          return ['image/jpeg', 'image/png', 'image/gif'].includes(value?.type);
        }
        return true; // Skip format validation for existing preview images
      }
    ).test(
      'fileSize',
      'File size should be less than 1MB',
      (value) => {
        // Only validate the file size if the value is a new File
        if (value && value instanceof File) {
          return value.size < 1024 * 1024; // Check file size
        }
        return true; // Skip size validation for existing preview images
      }
    ),
  });

  // Handle file input change for Image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue('Image', file); // Set the file in formik state
    if (file) {
      setImagePreview(URL.createObjectURL(file)); // Create a preview URL
    }
  };

  // Handle file input change for Preview Image
  const handlePreviewImageChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue('preview_image', file); // Set the file in formik state
    if (file) {
      setPreviewImagePreview(URL.createObjectURL(file)); // Create a preview URL
    }
  };
  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}servicecategory/get`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setServiceCategories(response.data);
    } catch (error) {
      console.error('Error fetching car categories:', error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchServiceCategories();
    }
  }, [token]);
  // Formik form initialization
  const formik = useFormik({
    initialValues: {
      title: '',
      Image: '',
      short_description: '',
      preview_image: '',
      service_category: '',
      description: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const services = modules.find(module => module.rolename === 'services');
        if (services) {
          setRolePermissions(services.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);

  const fetchLegalContents = async () => {
    axios.get(`${BASE_URL}service/get/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        const data = response.data;
        setPoints(Array.isArray(data.points) ? data.points : JSON.parse(data.points || '[]'));
        formik.setValues({
          title: data.title || '',
          Image: data.image || '',
          preview_image: data.preview_image || '',
          short_description: data.short_description || '',
          service_category: data.service_category || '',
          description: data.description || '',
        });
      })
      .catch(error => {
        console.error('Error fetching service details:', error);
      });

  };
  useEffect(() => {
    if (token) {
      fetchLegalContents();
    }
  }, [token]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);
  const handleUpdate = async (values) => {
    if (rolePermissions.write === 'no') {
        toast.dismiss();
        toast.error('You do not have permission to update data.', { autoClose: 1500 });
        return;
    }

    // Define validation rules for images (minimum width of 1000 pixels)
    const imageValidations = [
        {
            file: values.preview_image,
            minWidth: 1000,
            errorMessage: 'Preview image must have a minimum width of 1000 pixels.',
        },
        {
            file: values.Image,
            minWidth: 1000,
            errorMessage: 'Image must have a minimum width of 1000 pixels.',
        },
    ];

    // Validate each new image (skip validation if the file is not a new File)
    for (const { file, minWidth, errorMessage } of imageValidations) {
        // Check if the file is a new file
        if (file && file instanceof File) {
            console.log('Checking file:', file); // Debugging log

            const isValid = await checkImageMinWidth(file, minWidth);
            console.log(`Validation result for ${errorMessage}:`, isValid); // Debugging log

            if (!isValid) {
                toast.dismiss();
                toast.error(errorMessage, { autoClose: 1500 });
                return;
            }
        } else {
            console.log('Skipping validation for existing image:', file); // Skip existing images
        }
    }

    // Create FormData and append fields
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('short_description', values.short_description);
    formData.append('service_category', values.service_category);
    formData.append('description', values.description);
    formData.append('points', JSON.stringify(points));

    // Append new image files only
    if (values.Image && values.Image instanceof File) {
        formData.append('image', values.Image);
    }
    if (values.preview_image && values.preview_image instanceof File) {
        formData.append('preview_image', values.preview_image);
    }

    // Perform the update request
    axios.put(`${BASE_URL}service/update/${id}`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((response) => {
            toast.dismiss();
            toast.success('Service details updated successfully', { autoClose: 1500 });
            setTimeout(() => {
                setMessage(null);
                navigate(`/actionservice/${id}?action=view`);
            }, 1500);
        })
        .catch((error) => {
            console.error('Error updating service details:', error);
            toast.dismiss();
            toast.error('Failed to update service details', { autoClose: 1500 });
        });
};


const checkImageMinWidth = (file, minWidth) => {
  return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
          const isValid = img.width >= minWidth; // Check only the width
          resolve(isValid);
      };

      img.onerror = () => {
          console.error('Error loading image for validation.');
          resolve(false); // Invalid if image cannot be loaded
      };
  });
};


  const handleEdit = () => {
    navigate(`/actionservice/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/actionservice/${id}?action=view`);
  };

  const handleDescriptionChange = (value) => {
    formik.setFieldValue('description', value);
  };

  const handlePointChange = (e, index) => {
    const newPoints = [...points];
    newPoints[index] = e.target.value;
    setPoints(newPoints);
  };

  const handleRemovePoint = (index) => {
    const newPoints = [...points];
    newPoints.splice(index, 1);
    setPoints(newPoints);
  };

  const handleAddPoint = () => {
    setPoints([...points, '']);
  };

  return (
    <div className="container-xl my-4">
      <ToastContainer />
      <div className="row">
        <div className="col-md-6">
          <h4>Services</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/serviceslist" className="fs-6">Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className="fs-6">ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-2 mb-3">
                <div className="col-sm-3">
                  <label className="col-sm col-form-label" htmlFor="title">Title<span className='text-danger px-1 fs-5'>*</span></label>
                  <input
                    type="text"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    disabled={!isEditable}
                    className="form-control mb-2"
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <div className="text-danger">{formik.errors.title}</div>
                  ) : null}
                </div>
                <div className="col-sm-3">
                  <label className="col-sm col-form-label" htmlFor="service_category">Service Category<span className='text-danger px-1 fs-5'>*</span></label>
                  <select
                    name="service_category"
                    value={formik.values.service_category}
                    onChange={formik.handleChange}
                    disabled={!isEditable}
                    className="form-select mb-2"
                    style={{ color: !isEditable ? "#808080" : "#808080", }}
                  >
                    <option value="">Select</option>
                    {ServiceCategories.map(category => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  {formik.touched.service_category && formik.errors.service_category ? (
                    <div className="text-danger">{formik.errors.service_category}</div>
                  ) : null}
                </div>

                <div className="col-sm-6">
                  <label className="col-sm col-form-label" htmlFor="short_description">Short Description<span className='text-danger px-1 fs-5'>*</span></label>
                  <input
                    type="text"
                    name="short_description"
                    value={formik.values.short_description}
                    onChange={formik.handleChange}
                    disabled={!isEditable}
                    className="form-control mb-2"
                  />
                  {formik.touched.short_description && formik.errors.short_description ? (
                    <div className="text-danger">{formik.errors.short_description}</div>
                  ) : null}
                </div>
              </div>

              <div className="row g-2 mb-3">
                <div className="col-sm-3">
                  <label className="col-sm col-form-label" htmlFor="Image">Image {isEditable && "(1000 x 500)"}<span className='text-danger px-1 fs-5'>*</span></label>
                  <div className="mb-3">
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Image Preview"
                        className="img-fluid mb-2"
                        style={{ height: '220px', width: '100%', borderRadius: '5px' }}
                      />
                    ) : (
                      formik.values.Image && (
                        <img
                          src={`${BASE_URL}uploads/${formik.values.Image}`}
                          alt="Image Preview"
                          className="img-fluid mb-2"
                          style={{ height: '220px', width: '100%', borderRadius: '5px' }}
                        />
                      )
                    )}
                    {isEditable &&
                      <input
                        type="file"
                        name="Image"
                        onChange={handleImageChange}
                        disabled={!isEditable}
                        className="form-control"
                      />}
                  </div>
                  {formik.touched.Image && formik.errors.Image ? (
                    <div className="text-danger">{formik.errors.Image}</div>
                  ) : null}
                </div>

                <div className="col-sm-3">
                  <label className="col-sm col-form-label" htmlFor="preview_image">Preview Image{isEditable && " (1000 x 1200)"}<span className='text-danger px-1 fs-5'>*</span></label>
                  <div className="mb-3">
                    {previewImagePreview ? (
                      <img
                        src={previewImagePreview}
                        alt="Preview Image"
                        className="img-fluid mb-2"
                        style={{ height: '220px', width: '100%', borderRadius: '5px' }}
                      />
                    ) : (
                      formik.values.preview_image && (
                        <img
                          src={`${BASE_URL}uploads/${formik.values.preview_image}`}
                          alt="Preview Image"
                          className="img-fluid mb-2"
                          style={{ height: '220px', width: '100%', borderRadius: '5px' }}
                        />
                      )
                    )}
                    {isEditable &&
                      <input
                        type="file"
                        name="preview_image"
                        onChange={handlePreviewImageChange}
                        disabled={!isEditable}
                        className="form-control"
                      />}
                  </div>
                  {formik.touched.preview_image && formik.errors.preview_image ? (
                    <div className="text-danger">{formik.errors.preview_image}</div>
                  ) : null}
                </div>



                <div className="col-sm-6">
                  <label className="col-sm col-form-label" htmlFor="description">Description</label>
                  {isEditable ? (
                    <ReactQuill
                      theme="snow"
                      value={formik.values.description}
                      onChange={handleDescriptionChange}
                      modules={{
                        toolbar: [
                          [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                          [{ 'size': [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                          ['link', 'image'],
                          ['clean'],
                        ]
                      }}
                      style={{ height: '250px', borderRadius: '10px' }}
                      className="react-quill-editor mb-3"
                      readOnly={!isEditable}
                    />
                  ) : (
                    <div
                      className="form-control mb-2"
                      dangerouslySetInnerHTML={{ __html: formik.values.description }}
                      style={{ minHeight: '130px', backgroundColor: "#f4f4f4" }}
                    />
                  )}
                </div>
              </div>

              <div className="row g-2 mb-3">
                <div className="col-sm-6">
                  <label className="col-sm col-form-label" htmlFor="points"> Points</label>
                  {points.map((point, index) => (
                    <div className="input-group mb-2" key={index}>
                      <input
                        type="text"
                        value={point}
                        onChange={(e) => handlePointChange(e, index)}
                        disabled={!isEditable}
                        className="form-control"
                      />
                      {isEditable && (
                        <button
                          type="button"
                          onClick={() => handleRemovePoint(index)}
                          className="btn btn-label-secondary waves-effect waves-light"
                        >
                          <MdOutlineCancel />
                        </button>
                      )}
                    </div>
                  ))}
                  {isEditable && (
                    <button type="button" onClick={handleAddPoint} className="btn btn-primary btn-sm">
                      <IoAddCircleOutline />
                    </button>
                  )}
                </div>
              </div>

              {isEditable && (
                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary">Update</button>
                  <button type="button" onClick={handleCancel} className="btn btn-label-secondary mx-3">Cancel</button>
                </div>
              )}
              {!isEditable && rolePermissions.write === 'yes' && (
                <div className="d-flex justify-content-end">
                  <button type="button" onClick={handleEdit} className="btn btn-primary">Edit</button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionServices;
