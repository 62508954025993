import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY } from '../../../Constant/Constant';
import { useSelector } from 'react-redux';
import { IoAddCircleOutline } from "react-icons/io5";
import { MdOutlineCancel } from 'react-icons/md';

const ActionCarList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id } = useParams();
  const action = queryParams.get('action');
  const navigate = useNavigate();
  const [carDetails, setCarDetails] = useState(null);
  const [carCategories, setCarCategories] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [previewImagePreview, setPreviewImagePreview] = useState(null);

  const permissions = useSelector(state => state.roles.permissions.user_modules);
  const [rolePermissions, setRolePermissions] = useState({ read: 'no', write: 'no' });
  const [token, setToken] = useState(null);

  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken ? decryptedToken : null;
    } catch (error) {
      console.error('Error decrypting token:', error);
      return null;
    }
  };

  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = async () => {
    const encryptedBackendToken = localStorage.getItem('BackendToken');
    if (encryptedBackendToken) {
      const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
      setToken(decryptedToken); // Set token to state
    } else {
      console.warn('No BackendToken found in localStorage.');
    }
  };


  useEffect(() => {
    fetchBackendToken(); // Fetch and set token
  }, []);
  useEffect(() => {
    if (permissions) {
      try {
        const modules = JSON.parse(permissions);
        const car = modules.find(module => module.rolename === 'car');
        if (car) {
          setRolePermissions(car.permissions);
        }
      } catch (error) {
        console.error('Failed to parse permissions:', error);
      }
    }
  }, [permissions]);

  const fetchCar = async () => {
    axios.get(`${BASE_URL}car/get/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        const data = response.data;
        console.log("data", data)
        setCarDetails({
          title: data.title || '',
          image: data.image || '',
          preview_image: data.preview_image || '',
          short_description: data.short_description || '',
          Language: data.language_id || '',
          seats: data.seats || '',
          sortby: data.sortby || "",
          luggage: data.luggage || '',
          start_price: data.start_price || '',
          day_price: data.day_price || '',
          night_price: data.night_price || '',
          discount: data.discount || '',
          description: data.description || '',
          carcategory_id: data.carcategory_id || '',
          points: Array.isArray(data.points) ? data.points : JSON.parse(data.points || '[]'),
        });
      })
      .catch(error => {
        console.error('Error fetching car details:', error);
      });
  }
  useEffect(() => {
    if (token) {
      fetchCar();
    }
  }, [token]);

  useEffect(() => {
    setIsEditable(action === 'edit');
  }, [action]);
  const fetchCarCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}carcategory/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCarCategories(response.data);
    } catch (error) {
      console.error('Error fetching car categories:', error);
    }
  };
  useEffect(() => {

    fetchCarCategories();
  }, []);


  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    short_description: Yup.string().required('Short description is required'),
    seats: Yup.number().required('Seats are required').positive().integer(),
    luggage: Yup.number().required('Luggage is required').positive().integer(),
    start_price: Yup.number().required('Start price is required').positive(),
    day_price: Yup.number().required('Day price is required').positive(),
    night_price: Yup.number().required('Night price is required').positive(),
    discount: Yup.number().required('Discount is required').min(0).max(100),
    carcategory_id: Yup.string().required('Car category is required'),

    // Validation for new image upload only
    image: Yup.mixed().test(
      'fileRequired',
      'Image is required',
      function (value) {
        if (value && value instanceof File) {
          return true; // New file is present
        }
        return !!this.parent.image; // Return true if an existing image already exists
      }
    ).test(
      'fileFormat',
      'Unsupported format',
      function (value) {
        if (value && value instanceof File) {
          return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
        }
        return true; // Skip format validation for existing images
      }
    ).test(
      'fileSize',
      'File size should be less than 1MB',
      function (value) {
        if (value && value instanceof File) {
          return value.size < 1024 * 1024; // Check file size
        }
        return true; // Skip size validation for existing images
      }
    ),

    // Validation for new preview_image upload only
    preview_image: Yup.mixed().test(
      'fileRequired',
      'Preview Image is required',
      function (value) {
        if (value && value instanceof File) {
          return true; // New file is present
        }
        return !!this.parent.preview_image; // Return true if an existing preview image already exists
      }
    ).test(
      'fileFormat',
      'Unsupported format',
      function (value) {
        if (value && value instanceof File) {
          return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
        }
        return true; // Skip format validation for existing preview images
      }
    ).test(
      'fileSize',
      'File size should be less than 1MB',
      function (value) {
        if (value && value instanceof File) {
          return value.size < 1024 * 1024; // Check file size
        }
        return true; // Skip size validation for existing preview images
      }
    ),
  });


  const handleImageChange = (event, setFieldValue, isPreview = false) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      if (isPreview) {
        setPreviewImagePreview(previewUrl);
      } else {
        setImagePreview(previewUrl);
      }
      setFieldValue(isPreview ? 'preview_image' : 'image', file);
    }
  };

  const handleEdit = () => {
    navigate(`/actioncarlist/${id}?action=edit`);
  };

  const handleCancel = () => {
    navigate(`/actioncarlist/${id}?action=view`);
  };

  const handlepointsChange = (e, index) => {
    const newpoints = [...carDetails.points];
    newpoints[index] = e.target.value;
    setCarDetails(prevState => ({
      ...prevState,
      points: newpoints
    }));
  };

  const handleRemovePoint = (index) => {
    const newpoints = [...carDetails.points];
    newpoints.splice(index, 1);
    setCarDetails(prevState => ({
      ...prevState,
      points: newpoints
    }));
  };

  const handleAddPoint = () => {
    setCarDetails(prevState => ({
      ...prevState,
      points: [...prevState.points, '']
    }));
  };
  if (!carDetails) return <div>Loading...</div>;

  const handleUpdate = async (values) => {
    // Define validation rules for images (minimum width 1000 pixels, no height validation)
    const imageValidations = [
      {
        file: values.preview_image,
        minWidth: 1000, // Minimum width only
        errorMessage: 'Preview image must have a minimum width of 1000 pixels.',
      },
      {
        file: values.image,
        minWidth: 1000, // Minimum width only
        errorMessage: 'Image must have a minimum width of 1000 pixels.',
      },
    ];

    // Validate each new image (skip validation if the file is not a new File)
    for (const { file, minWidth, errorMessage } of imageValidations) {
      // Check if the file is a new file
      if (file && file instanceof File) {
        console.log('Checking file:', file); // Debugging log

        const isValid = await checkImageMinWidth(file, minWidth);
        console.log(`Validation result for ${errorMessage}:`, isValid); // Debugging log

        if (!isValid) {
          toast.dismiss();
          toast.error(errorMessage, { autoClose: 1500 });
          return;
        }
      } else {
        console.log('Skipping validation for existing image:', file); // Skip existing images
      }
    }

    // Ensure points is an array and has the correct format
    const pointsArray = Array.isArray(values.points)
      ? values.points
      : values.points
        .split(',')
        .map((point) => point.trim())
        .filter((point) => point);

    // Prepare form data
    const formData = new FormData();
    formData.append('title', values.title);
    if (values.image instanceof File) formData.append('image', values.image);
    if (values.preview_image instanceof File) formData.append('preview_image', values.preview_image);
    formData.append('short_description', values.short_description);
    formData.append('Language', values.Language);
    formData.append('seats', values.seats);
    formData.append('luggage', values.luggage);
    formData.append('start_price', values.start_price);
    formData.append('day_price', values.day_price);
    formData.append('night_price', values.night_price);
    formData.append('discount', values.discount);
    formData.append('sortby', values.sortby);
    formData.append('description', values.description);
    formData.append('carcategory_id', values.carcategory_id);
    formData.append('points', JSON.stringify(pointsArray)); // Send points as a JSON string

    try {
      await axios.put(`${BASE_URL}car/update/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Car details updated successfully', { autoClose: 1500 });
      navigate(`/actioncarlist/${id}?action=view`);
    } catch (error) {
      toast.error('Failed to update car details', { autoClose: 1500 });
    }
  };


  const checkImageMinWidth = (file, minWidth) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const isValid = img.width >= minWidth; // Validate the minimum width
        resolve(isValid);
      };

      img.onerror = () => {
        console.error('Error loading image for validation.');
        resolve(false); // Mark as invalid if the image cannot be loaded
      };
    });
  };




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarDetails({
      ...carDetails,
      [name]: value,  // Make sure this updates the specific field
    });
  };
  return (
    <div className="container-xl my-4">
      <div className="row">
        <div className="col-md-6">
          <h4>Cars</h4>
        </div>
        <div className="col-md-6">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end fs-3">
              <li className="breadcrumb-item"><Link to="/carlist" className='fs-6'>Back</Link></li>
              <li className="breadcrumb-item"><Link to="#" className='fs-6'>ActionPage</Link></li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="col-12 mt-3">
        <div className="card mt-3">
          <div className="card-header header-underline pb-1">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-name">{action === 'edit' ? 'Edit Details' : 'View Details'}</h5>
            </div>
          </div>
          <div className="card-body mt-3">
            <Formik
              initialValues={carDetails}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
              enableReinitialize
            >
              {({ setFieldValue, values, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row g-2 ">
                    <div className="col-sm-3">
                      <label htmlFor="carcategory_id" className="col-sm col-form-label">Car Category<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        as="select"
                        name="carcategory_id"
                        className="form-select"
                        disabled={!isEditable}
                        style={{ color: !isEditable ? "#808080" : "#808080", }}
                      >
                        <option value="">Select Car Category</option>
                        {carCategories.map(category => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="carcategory_id" component="div" className="text-danger" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="title" className="col-sm col-form-label">Title<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        type="text"
                        name="title"
                        className="form-control"
                        disabled={!isEditable}
                        value={carDetails.title}
                        onChange={handleInputChange}
                      />
                      <ErrorMessage name="title" component="div" className="text-danger" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="seats" className="col-sm col-form-label">Passager Count<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        type="number"
                        min="0"
                        name="seats"
                        className="form-control"
                        disabled={!isEditable}
                        value={carDetails.seats}
                        onChange={handleInputChange}
                      />
                      <ErrorMessage name="seats" component="div" className="text-danger" />

                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="luggage" className="col-sm col-form-label">Luggage Count<span className='text-danger px-1 fs-5'>*</span></label>
                      <Field
                        type="number"
                        min="0"
                        name="luggage"
                        value={carDetails.luggage}
                        onChange={handleInputChange}
                        className="form-control"
                        disabled={!isEditable}
                      />
                      <ErrorMessage name="luggage" component="div" className="text-danger" />
                    </div>


                    <div className="row g-2 ">
                      <div className="col-sm-3">
                        <label htmlFor="start_price" className="col-sm col-form-label">Start Price<span className='text-danger px-1 fs-5'>*</span></label>
                        <Field
                          type="number"
                          min="0"
                          autocomplete="off"
                          step="0.01" 
                          name="start_price"
                          className="form-control"
                          value={carDetails.start_price}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                        <ErrorMessage name="start_price" component="div" className="text-danger" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="day_price" className="col-sm col-form-label">Day Price<span className='text-danger px-1 fs-5'>*</span></label>
                        <Field
                          type="number"
                          min="0"
                          autocomplete="off"
                          step="0.01" 
                          name="day_price"
                          value={carDetails.day_price}
                          onChange={handleInputChange}
                          className="form-control"
                          disabled={!isEditable}
                        />
                        <ErrorMessage name="day_price" component="div" className="text-danger" />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="night_price" className="col-sm col-form-label">Night Price<span className='text-danger px-1 fs-5'>*</span></label>
                        <Field
                          type="number"
                          min="0"
                          autocomplete="off"
                          step="0.01" 
                          name="night_price"
                          value={carDetails.night_price}
                          onChange={handleInputChange}
                          className="form-control"
                          disabled={!isEditable}
                        />
                        <ErrorMessage name="night_price" component="div" className="text-danger" />

                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="sortby" className="col-sm col-form-label">Order By</label>
                        <Field
                          type="number"
                          min="0"

                          name="sortby"
                          value={carDetails.sortby}
                          onChange={handleInputChange}
                          className="form-control"
                          disabled={!isEditable}
                        />
                      </div>
                    </div>

                    <div className="row g-2 ">
                      <div className="col-sm-6">
                        <label htmlFor="discount" className="col-sm col-form-label">Discount</label>
                        <Field
                          type="number"
                          min="0"
                          autocomplete="off"
                          step="0.01" 
                          value={carDetails.discount}
                          onChange={handleInputChange}
                          name="discount"
                          className="form-control"
                          disabled={!isEditable}
                        />
                        <ErrorMessage name="discount" component="div" className="text-danger" />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="short_description" className="col-sm col-form-label">Short Description</label>
                        <Field
                          type="text"
                          name="short_description"
                          className="form-control"
                          value={carDetails.short_description}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                        <ErrorMessage name="short_description" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="row g-2 ">
                      <div className="col-sm-6">
                        <label htmlFor="image" className="form-label">Image {isEditable && "(1920 x 760)"}<span className='text-danger px-1 fs-5'>*</span></label>
                        {isEditable &&
                          <input
                            id="image"
                            name="image"
                            type="file"
                            className="form-control"
                            disabled={!isEditable}
                            onChange={event => handleImageChange(event, setFieldValue)}
                          />
                        }
                        {imagePreview ? (
                          <img src={imagePreview} alt="Preview" className="img-fluid mb-2 form-control" />
                        ) : (
                          values.image && (
                            <img
                              src={`${BASE_URL}uploads/${values.image}`}
                              alt="Preview"
                              className="img-fluid mb-2 form-control"
                            />
                          )
                        )}
                      </div>
                      <ErrorMessage name="image" component="div" className="text-danger" />
                      <div className="col-sm-6">
                        <label htmlFor="preview_image" className="col-sm col-form-label">Preview Image {isEditable && "(1530 x 711)"}<span className='text-danger px-1 fs-5'>*</span></label>
                        {isEditable &&
                          <input
                            id="preview_image"
                            name="preview_image"
                            type="file"
                            className="form-control"
                            disabled={!isEditable}
                            onChange={event => handleImageChange(event, setFieldValue, true)}
                          />
                        }
                        {previewImagePreview ? (
                          <img src={previewImagePreview} alt="Preview" className="img-fluid mb-2 form-control" />
                        ) : (
                          values.preview_image && (
                            <img
                              src={`${BASE_URL}uploads/${values.preview_image}`}
                              alt="Preview"
                              className="img-fluid mb-2 form-control"
                            />
                          )
                        )}
                      </div>
                      <ErrorMessage name="preview_image" component="div" className="text-danger" />

                    </div>
                    <div className="row g-2 ">
                      <div className="col-sm-6">
                        <label className="col-sm col-form-label">Description</label>
                        {isEditable ? (
                          <ReactQuill
                            theme="snow"
                            value={values.description}
                            onChange={value => setFieldValue('description', value)}
                            modules={{
                              toolbar: [
                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                [{ 'size': [] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                ['link', 'image'],
                                ['clean'],
                              ],
                            }}
                            className="react-quill-editor "
                          />
                        ) : (
                          <div
                            className="form-control mb-2"
                            dangerouslySetInnerHTML={{ __html: values.description }}
                            style={{ minHeight: '150px', backgroundColor: '#f4f4f4' }}
                          />
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label className="col-sm col-form-label" >Points</label>
                        <div className=''>
                          {carDetails.points.map((point, index) => (
                            <div key={index} className='input-group '>
                              <input
                                type="text"
                                value={point}
                                onChange={(e) => handlepointsChange(e, index)}

                                disabled={!isEditable}
                                className="form-control mb-2 "
                              />
                              {isEditable && (
                                <button type="button" className='btn btn-label-secondary  waves-effect waves-light mb-2' onClick={() => handleRemovePoint(index)}>
                                  <MdOutlineCancel />
                                </button>
                              )}
                            </div>
                          ))}
                          {isEditable && (
                            <button type="button" className="btn btn-primary " onClick={handleAddPoint}>
                              <IoAddCircleOutline style={{ fontSize: "16px" }} />
                            </button>
                          )}
                        </div>
                      </div>

                    </div>

                    <div className='card-footer'>
                      <div className="d-flex justify-content-end align-items-center">
                        {action === 'view' && rolePermissions.write === 'yes' && (
                          <button type="button" onClick={handleEdit} className="btn btn-primary mb-2">Edit</button>
                        )}
                        {isEditable && (
                          <button type="submit" className="btn btn-primary mx-3">Update</button>
                        )}
                        {action === 'edit' && (
                          <button type="button" onClick={() => navigate(`/actioncarlist/${id}?action=view`)} className='btn btn-label-secondary'>Cancel</button>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

          </div>

        </div>
      </div>
      <ToastContainer />
    </div >
  );
};

export default ActionCarList;
