import React from 'react';

const TableHeader = ({ data, checkbox, canEdit, columns, handleSelectAll, selectedRows, isBookingListPage, isCustomerPage, handleSort, getSortIndicator }) => {
    return (
        <thead className="table-light ">
            <tr>

                {data.length > 0 && canEdit && !isBookingListPage && !checkbox && (
                    <th>
                        <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedRows.every(Boolean)}
                        />
                    </th>
                )}

                {columns.map(column => (
                    <th key={column.key}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>{column.label.replace('_', ' ')}</span>
                            <button
                                onClick={() => handleSort(column.key)}
                                className="btn btn-link p-0"
                                style={{ marginLeft: 'auto' }}
                            >
                                {getSortIndicator(column.key)}
                            </button>
                        </div>
                    </th>
                ))}
                {!isCustomerPage && (
                    <th style={{ width: "50px" }}>Actions</th>)}
            </tr>
        </thead>
    );
};

export default TableHeader;
