import React, { useEffect, useState, useCallback } from 'react';
import { BASE_URL, CRYPTOJS_ENCRYPTION_KEY, CRYPTOJS_ENCRYPTION_KEY_2 } from '../Constant/Constant';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BookingList from '../Components/Booking/BookingList';
import RecentList from '../Components/Booking/RecentList';


const Dashboard = () => {
  const [data, setData] = useState([]); // State for booking data
  const [lastBookingId, setLastBookingId] = useState(null); // Last booking ID
  const [newBookingsCount, setNewBookingsCount] = useState(0); // New bookings count
  const [pendingCount, setPendingCount] = useState(0); // Pending bookings count
  const [completedCount, setCompletedCount] = useState(0); // Completed bookings count
  const [canceledCount, setCanceledCount] = useState(0); // Canceled bookings count
  const [totalAmount, setTotalAmount] = useState(0); // Total amount count
  const [newNotificationCount, setNewNotificationCount] = useState(0); // Notification count
  const [todayBookingsCount, setTodayBookingsCount] = useState(0); // Today's bookings count
  const [token, setToken] = useState(null);
  const [languageId, setLanguageId] = useState(null);

  // Function to decrypt tokens
  const decryptToken = (encryptedToken, key) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken || null;
    } catch (error) {
      console.error('Error decrypting token:', error);
      return null;
    }
  };
console.log("token dashoboard",token)
  // Fetch token from localStorage and decrypt it
  const fetchBackendToken = useCallback(() => {
    const encryptedBackendToken = localStorage.getItem('BackendToken');
    if (encryptedBackendToken) {
      const decryptedToken = decryptToken(encryptedBackendToken, CRYPTOJS_ENCRYPTION_KEY);
      if (decryptedToken) {
        setToken(decryptedToken); // Set token to state
      } else {
        console.error("Failed to decrypt backend token.");
      }
    } else {
      console.warn('No BackendToken found in localStorage.');
    }
  }, []);

  // Fetch language from localStorage and decrypt it
  const fetchLanguage = useCallback(() => {
    const encryptedLanguage = localStorage.getItem('selectedLanguageId');
    if (encryptedLanguage) {
      const decryptedLanguage = decryptToken(encryptedLanguage, CRYPTOJS_ENCRYPTION_KEY_2);
      if (decryptedLanguage) {
        setLanguageId(decryptedLanguage);
      } else {
        console.error("Failed to decrypt languageId.");
      }
    } else {
      console.warn('No selectedLanguageId found in localStorage.');
    }
  }, []);

  useEffect(() => {
    fetchBackendToken(); // Fetch and set token
    fetchLanguage(); // Fetch and set language ID
  }, [fetchBackendToken, fetchLanguage]);

  const fetchData = useCallback(async () => {
    if (!token || !languageId) return; // Ensure token and languageId are available before fetching data

    try {
      const response = await fetch(`${BASE_URL}booking/get?language_id=${languageId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      const result = await response.json();

      if (Array.isArray(result) && result.length > 0) {
        setData(result);

        // Detect new bookings by comparing the first booking's ID with the previously stored one
        const newBookings = result.filter(booking => lastBookingId && booking.id > lastBookingId);

        setNewBookingsCount(newBookings.length); // Set the count of new bookings only
        setNewNotificationCount(newBookings.length); // Update the count passed to the header

        // Update the last booking ID to the latest one
        setLastBookingId(result[0].id);

        // Get today's date in YYYY-MM-DD format
        const today = new Date().toISOString().split('T')[0];

        // Filter bookings created today
        const todayBookings = result.filter(booking => booking.created_at.split('T')[0] === today);
        setTodayBookingsCount(todayBookings.length); // Set the count of today's bookings

        // Sum the amounts for today's bookings
        const todayTotalAmount = todayBookings.reduce((acc, booking) => acc + Number(booking.total_price), 0);

        // Now filter today's bookings by status
        const pendingCount = todayBookings.filter(booking => booking.status === 'process').length;
        const completedCount = todayBookings.filter(booking => booking.status === 'complete').length;
        const canceledCount = todayBookings.filter(booking => booking.status === 'cancel').length;

        // Set the state with today's data
        setPendingCount(pendingCount);
        setCompletedCount(completedCount);
        setCanceledCount(canceledCount);
        setTotalAmount(todayTotalAmount); // Update the total amount to today's total
      } else {
        console.warn('No data found for the given language ID.');
      }
    } catch (error) {
      toast.error('Error fetching data: ' + error.message, {
        autoClose: 2000,
      });
    }
  }, [token, languageId, lastBookingId]);

  // Fetch data initially and set up a 1-minute interval to refresh the data
  useEffect(() => {
    if (token && languageId) {
      fetchData(); // Fetch data when token and languageId are set

      // Set an interval to refetch the data every 1 minute (60000ms)
      const intervalId = setInterval(() => {
        fetchData();
      }, 60000);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [token, languageId, lastBookingId, fetchData]); // Depend on token, languageId, lastBookingId, and fetchData

  // Get only the first 5 bookings from the data
  const firstFiveMessages = data.slice(0, 5);
console.log("firstFiveMessages",firstFiveMessages)
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y mb-0 ">
        <Helmet>
          <title>Dashbord </title>
          <meta name="description" content="This is the dashboard page of the application" />
        </Helmet>
        <h4 className="py-3 mb-4"><span className="text-muted fw-light"></span> Dashboard</h4>


        <div className="row mb-4">
          <div className="col-sm-6 col-lg-3 mb-4">
            <div className="card card-border-shadow-primary">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-primary "><i class="ti ti-brand-booking fs-2"></i></span>
                  </div>
                  <h4 className="ms-1 mb-0">{todayBookingsCount}</h4>
                </div>
                <p className="mb-1">Today Bookings</p>
                {/* <p className="mb-0">
                  <span className="fw-medium me-1">+18.2%</span>
                  <small className="text-muted">than last week</small>
                </p> */}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-2 mb-4">
            <div className="card card-border-shadow-success">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-success"><svg  xmlns="http://www.w3.org/2000/svg"  width="28"  height="28"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-circle-dashed-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" /><path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" /><path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" /><path d="M8.56 20.31a9 9 0 0 0 3.44 .69" /><path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" /><path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" /><path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" /><path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" /><path d="M9 12l2 2l4 -4" /></svg></span>
                  </div>
                  <h4 className="ms-1 mb-0">{completedCount}</h4>
                </div>
                <p className="mb-1">Today Completed</p>
                {/* <p className="mb-0">
                  <span className="fw-medium me-1">-2.5%</span>
                  <small className="text-muted">than last week</small>
                </p> */}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-2 mb-4">
            <div className="card card-border-shadow-warning">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-warning"><i className="ti ti-clock ti-md"></i></span>
                  </div>
                  <h4 className="ms-1 mb-0">{pendingCount}</h4>
                </div>
                <p className="mb-1">Today Pending</p>
                {/* <p className="mb-0">
                  <span className="fw-medium me-1">-8.7%</span>
                  <small className="text-muted">than last week</small>
                </p> */}
              </div>
            </div>
          </div>
          
          <div className="col-sm-6 col-lg-2 mb-4">
            <div className="card card-border-shadow-danger">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-danger"><i className="ti ti-alert-triangle ti-md"></i></span>
                  </div>
                  <h4 className="ms-1 mb-0">{canceledCount}</h4>
                </div>
                <p className="mb-1">Today Canceled</p>
                {/* <p className="mb-0">
                  <span className="fw-medium me-1">+4.3%</span>
                  <small className="text-muted">than last week</small>
                </p> */}
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-4">
            <div className="card card-border-shadow-info">
              <div className="card-body">
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-info"><svg  xmlns="http://www.w3.org/2000/svg"  width="28"  height="28"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" /><path d="M9 12l2 2l4 -4" /></svg></span>
                  </div>
                  <h4 className="ms-1 mb-0">CHF{totalAmount}</h4>
                </div>
                <p className="mb-1">Today Total Amount</p>
                {/* <p className="mb-0">
                  <span className="fw-medium me-1">-2.5%</span>
                  <small className="text-muted">than last week</small>
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <RecentList firstFiveMessages={firstFiveMessages}/>
        </div>
  
</>
     
    
  );
};

export default Dashboard;
